import { React } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Settings from "../components/settings";

const SettingsPage = () => {
    return (
        <div className="bg-secondary">
            <Header />
            <Settings />
            <Footer />
        </div>
    );
}

export default SettingsPage;