import React, { useState, useEffect } from 'react'
import Api from '../modules/Api'
import { useProfile } from '../modules/profileContext'
import { useParams, useNavigate } from 'react-router-dom'
import { Card, Col, ListGroup } from 'react-bootstrap'
import Loader from './loader'
import CreatePostForm from './createPostForm'
import { getFullDate, canSendMessage } from '../modules/utils'
import Post from './post'

const UserProfile = () => {
    const navigate = useNavigate()
    const { profile } = useProfile()
    const { id } = useParams()
    const [userProfile, setUserProfile] = useState(null)
    const [isOwnProfile, setIsOwnProfile] = useState(false)
    const [posts, setPosts] = useState([])
    const [chatUsers, setChatUsers] = useState([])

    const getPosts = async () => {
        const { result, status } = await Api.getPosts(userProfile?.id)
        setPosts(status == 200 ? result.data : [])
    }

    const handleFriendButton = async (action) => {
        if (userProfile.friendship_status === "accepted") {
            const { status } = await Api.deleteFriendRequest({ receiver_id: userProfile.id })
            if (status === 204) {
                setUserProfile({ ...userProfile, friendship_status: null })
            }
        }
        else if (userProfile.friendship_status === "pending") {
            if (userProfile.friendship_sender_id === profile.id) {
                const { status } = await Api.deleteFriendRequest({ receiver_id: userProfile.id })
                if (status === 204) {
                    setUserProfile({ ...userProfile, friendship_status: null })
                }
            }
            else {
                if (action === "accept") {
                    const { status } = await Api.updateFrindshipStatus(userProfile.friendship_id, { response: "accepted" })
                    if (status === 204) {
                        setUserProfile({ ...userProfile, friendship_status: "accepted" })
                    }
                }
                else if (action === "reject") {
                    const { status } = await Api.updateFrindshipStatus(userProfile.friendship_id, { response: "rejected" })
                    if (status === 204) {
                        setUserProfile({ ...userProfile, friendship_status: "rejected" })
                    }
                }
            }
        }
        else if (!userProfile.friendship_status) {
            const { status } = await Api.createFriendRequest({ receiver_id: userProfile.id })
            if (status === 201) {
                setUserProfile({ ...userProfile, friendship_status: "pending", friendship_sender_id: profile.id })
            }
        }
    }

    useEffect(() => {
        const fetchUser = async () => {
            if (id && id != profile?.id) {
                const { result, status } = await Api.getUser(id)
                if (status === 200) {
                    setUserProfile(result.data)
                    setIsOwnProfile(false)
                }
            }
            else {
                setUserProfile(profile)
                setIsOwnProfile(true)
            }
        }
        fetchUser()
        const getChatUsers = async () => {
            const { result, status } = await Api.getChatUsers(profile?.id)
            if (status === 200) {
                setChatUsers(result.data)
            }
        }
        getChatUsers()
    }, [id, profile])

    useEffect(() => {
        getPosts()
    }, [userProfile])

    if (!userProfile) {
        return <Loader />
    }

    return (
        <div className="minHeight mt-3 d-flex flex-lg-row flex-column">
            <Col sm={12} xs={12} md={10} lg={6} xl={6} className="mx-auto">
                <Card border="secondary" className="text-center shadow mb-4 mb-lg-0">
                    <Card.Header className="bg-dark text-white">{userProfile.name}</Card.Header>
                    <Card.Img variant="top" src={`${Api.host}${userProfile.avatar}`} className='avatar-preview m-auto mt-1' style={{ cursor: "auto" }} />
                    <Card.Body>
                        <Card.Title>{userProfile.username}</Card.Title>
                        <Card.Text>Дата регистрации: {getFullDate(userProfile.date_created)}</Card.Text>
                        {!isOwnProfile && (
                            <>
                                <div className='d-grid mb-2'>
                                    <button
                                        disabled={(!canSendMessage(userProfile, chatUsers))}
                                        className='btn btn-dark col'
                                        onClick={() => navigate(`/messages/${userProfile.id}`)}>
                                        Сообщения
                                    </button>
                                </div>
                                {!userProfile.friendship_status && (
                                    <div className='d-grid mb-2'>
                                        <button className='btn btn-dark col' onClick={() => handleFriendButton()}>Добавить в друзья</button>
                                    </div>
                                )}
                                {userProfile.friendship_status === "pending" && (
                                    userProfile.friendship_sender_id === profile.id ? (
                                        <div className='d-grid mb-2'>
                                            <button className='btn btn-outline-dark col' onClick={() => handleFriendButton()}>Отменить приглашение</button>
                                        </div>
                                    ) : (
                                        <>
                                            <div className='d-grid mb-2'>
                                                <button className='btn btn-dark col' onClick={() => handleFriendButton("accept")}>Принять приглашение</button>
                                            </div>
                                            <div className='d-grid mb-2'>
                                                <button className='btn btn-dark col' onClick={() => handleFriendButton("reject")}>Отклонить приглашение</button>
                                            </div>
                                        </>
                                    )
                                )}
                                {userProfile.friendship_status === "accepted" && (
                                    <>
                                        <div className='d-grid'>
                                            <button className='btn btn-dark col' onClick={() => handleFriendButton()}>Удалить из друзей</button>
                                        </div>
                                    </>
                                )}
                                {userProfile.friendship_status === "rejected" && (
                                    <p className='text-center'>Запрос в друзья отклонен</p>
                                )}
                            </>
                        )}
                    </Card.Body>
                </Card>
            </Col>
            <Col sm={12} xs={12} md={10} lg={5} xl={5} className='bg-light minHeight mx-auto'>
                {isOwnProfile && <CreatePostForm onPostCreated={getPosts} />}
                <hr />
                <ListGroup variant="flush">
                    {posts.length > 0 ? (
                        posts.map((post, index) => (
                            <Post
                                post={post}
                                onGetPost={(result, postId) => setPosts(posts.map(post => post.id === postId ? result.data : post))}
                                onDeletePost={(postToDelete) => setPosts(posts.filter(post => post.id !== postToDelete.id))}
                                index={index}
                            />
                        ))
                    ) : (
                        <div className='bg-transparent text-center text-muted'>У пользователя нет постов</div>
                    )}
                </ListGroup>
            </Col>
        </div>
    )
}

export default UserProfile