import { React, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Modal, Form, Container, Row, Col } from "react-bootstrap";
import Api from "../modules/Api";

const RegisterForm = () => {
    const [response, setResponse] = useState({ result: { errors: [] }, status: "" })

    const { register, handleSubmit, formState } = useForm({ mode: "onChange" })
    const onSubmit = async (data) => {
        const { result, status } = await Api.register(data)
        setResponse({ result, status })
    }

    const closeModal = () => {
        setResponse({ result: { errors: [] }, status: "" })
    }

    const navigate = useNavigate()

    return (
        <Container className="minHeight">
            <Row className="justify-content-center">
                <Col md={6} className="mt-3 pb-3 bg-light rounded shadow">
                    <h1 className="text-center">Регистрация</h1>
                    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="name">Имя</Form.Label>
                            <Form.Control
                                type="text"
                                id="name"
                                name="name"
                                {...register("name", { required: true, minLength: 3 })}
                                isValid={formState.touchedFields.name && !formState.errors.name}
                                isInvalid={!!formState.errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formState.errors.name?.type === "required" && "Пожалуйста, введите имя"}
                                {formState.errors.name?.type === "minLength" && "Пожалуйста, введите имя длиной не менее 3 символов"}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Имя пользователя</Form.Label>
                            <Form.Control
                                type="text"
                                isValid={formState.touchedFields.username && !formState.errors.username}
                                isInvalid={!!formState.errors.username}
                                id="username"
                                name="username"
                                {...register("username", { required: true, minLength: 5 })}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formState.errors.username?.type === "required" && "Пожалуйста, введите имя пользователя"}
                                {formState.errors.username?.type === "minLength" && "Пожалуйста, введите имя пользователя длиной не менее 5 символов"}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Электронная почта</Form.Label>
                            <Form.Control
                                type="email"
                                id="email"
                                name="email"
                                isValid={formState.touchedFields.email && !formState.errors.email}
                                isInvalid={!!formState.errors.email}
                                {...register("email", { required: true, pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ })}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formState.errors.email?.type === "required" && "Пожалуйста, введите действительный адрес электронной почты"}
                                {formState.errors.email?.type === "pattern" && "Пожалуйста, введите корректный адрес электронной почты"}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Пароль</Form.Label>
                            <Form.Control
                                type="password"
                                id="password"
                                name="password"
                                isValid={formState.touchedFields.password && !formState.errors.password}
                                isInvalid={!!formState.errors.email}
                                {...register("password", { required: true, minLength: 7 })}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formState.errors.password?.type === "required" && "Пожалуйста, введите пароль"}
                                {formState.errors.password?.type === "minLength" && "Пожалуйста, введите пароль из не менее чем 7 символов"}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <div className="d-grid">
                            <button type="submit" className="btn btn-dark">Зарегистрироваться</button>
                        </div>
                        <div className="mt-1 text-center">
                            <p className="m-auto p-2 d-inline-block">Уже есть аккаунт?</p>
                            <Link className="btn btn-dark d-inline-block" to="/login">Войти</Link>
                        </div>
                    </Form>
                </Col>
            </Row>
            <Modal show={response.status} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{response.status === 201 ? "Успешно!" : "Упс..."}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{response.status === 201 ? "Спасибо за регистрацию!" : `Что-то пошло не так: ${response.result.errors.join('\n')}`}</Modal.Body>
                <Modal.Footer>
                    {response.status === 201 && (<Button variant="dark" onClick={() => {
                        navigate("/login")
                    }}>Войти</Button>)}
                    <Button variant="secondary" onClick={closeModal}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default RegisterForm