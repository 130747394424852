import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import UsersList from "./usersList"
import ChatWindow from "./chatWindow"
import { Row, Col, Container } from "react-bootstrap"
import Api from "../modules/Api"
import { useProfile } from "../modules/profileContext"
import Loader from "./loader"

const MessagesTab = () => {
    const { id: userId } = useParams()
    const [selectedUser, setSelectedUser] = useState(null)
    const { profile } = useProfile()
    const [chatUsers, setChatUsers] = useState([])
    const [allChatUsers, setAllChatUsers] = useState([])
    const [searchTerm, setSearchTerm] = useState(null)

    const getChatUsers = async () => {
        const { result, status } = await Api.getChatUsers(profile?.id)
        if (status === 200) {
            setChatUsers(result.data)
            setAllChatUsers(result.data)
        }
    }

    useEffect(() => {
        getChatUsers()
        if (!userId || userId == profile?.id) return
        const getUser = async () => {
            const { result, status } = await Api.getUser(userId)
            if (status === 200) {
                setSelectedUser(result.data)
            }
        }
        getUser()
    }, [profile])

    useEffect(() => {
        if (!searchTerm) {
            setChatUsers(allChatUsers)
            return
        }
        setChatUsers(() => allChatUsers.filter(user => user.name.toLowerCase().indexOf(searchTerm.toLowerCase()) != -1))
    }, [searchTerm])

    if (!profile) return <Loader pleaseEnter={true} />
    return (
        <Container className="minHeight mt-2 d-flex flex-column">
            <Row className="d-flex flex-column flex-md-row flex-grow-1 w-100 m-auto">
                <Col xs="auto">
                    <input type="search" id="userSearchMessages" style={{ border: "none", boxShadow: "none" }} className="form-control bg-dark text-white d-flex flex-row flex-md-column mx-auto mb-2" onChange={(e) => setSearchTerm(e.target.value)} placeholder="Введите имя" />
                    <UsersList users={chatUsers} onUserSelect={setSelectedUser} />
                </Col>
                <Col xs className="d-flex bg-dark justify-content-center align-items-center mx-0 mx-md-2">
                    <ChatWindow selectedUser={selectedUser} chatUsers={chatUsers} setChatUsers={setChatUsers} userSearchTerm={searchTerm} />
                </Col>
            </Row>
        </Container>
    )
}

export default MessagesTab