const imagePreview = (file, setFile, callback) => {
    if (file) {
        setFile(file)
        const reader = new FileReader()
        reader.onloadend = () => {
            callback(reader.result)
        }
        reader.readAsDataURL(file)
    }
}
export default imagePreview