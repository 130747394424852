import { useState } from "react"
import { useForm } from 'react-hook-form'
import Picker from 'emoji-picker-react'
import { Form, Col, Button } from "react-bootstrap"
import Api from "../modules/Api"
import RussianName from "../modules/russianName"

const CreateCommentForm = ({ postId, onCommentSent, answerOnComment, setAnswerOnComment }) => {
    const [showEmojiPicker, setShowEmojiPicker] = useState(false)
    const { register, handleSubmit, reset, setValue, getValues } = useForm()
    const onSubmit = async (data) => {
        data.parent_id = answerOnComment?.id
        const { status } = await Api.createComment(postId, data)
        if (status == 201) {
            onCommentSent(data)
        }
        setAnswerOnComment(null)
        reset()
    }

    const onEmojiClick = (emojiObject) => {
        setValue("text", getValues('text') + emojiObject.emoji)
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="my-2">
            <div className="m-auto d-flex flex-md-row flex-column">
                <Col sm={12} xs={12} md={6} lg={6} xl={6} className="m-auto">
                    {answerOnComment && <div className="d-flex flex-row mx-2 mx-md-auto">
                        <small className="text-break align-self-start">Ответ на комментарий <span className="fw-bold">{new RussianName(answerOnComment.author.name)?.fullName("genitive") || answerOnComment.author.name}</span>: {answerOnComment.text}</small>
                        <Button variant="outline-dark" style={{ width: "fit-content", height: "fit-content" }} className="mx-auto align-self-center" onClick={() => setAnswerOnComment(null)}><span aria-hidden="true">&times;</span></Button>
                    </div>
                    }
                    <Form.Group>
                        <Form.Control
                            className="bg-dark text-white"
                            style={{ border: "none", boxShadow: "none" }}
                            rows={3}
                            {...register("text", {
                                required: true,
                                validate: value => value.trim().length !== 0
                            })}
                        />
                    </Form.Group>
                </Col>
                <div className="d-md-block d-flex justify-content-center align-self-end m-auto mb-0 mt-1 mt-md-0">
                    <Button variant='outline-dark' className='me-2' onClick={() => setShowEmojiPicker(!showEmojiPicker)}>Эмодзи</Button>
                    <Button variant="dark" type="submit">Отправить</Button>
                </div>
            </div>
            {showEmojiPicker && <Picker width="100%" className='mb-2' onEmojiClick={onEmojiClick} />}
        </Form>
    )
}

export default CreateCommentForm