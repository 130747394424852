import { io } from "socket.io-client"

class Api {
    static host = "https://geekly.nazarov-do-it.ru"
    static socket = io(Api.host)

    static async _request(url, options) {
        try {
            const response = await fetch(`${Api.host}${url}`, options)
            const status = response.status
            let result = null
            if (status !== 204) {
                result = await response.json()
            }
            return { result, status }
        } catch (error) {
            console.error(error)
            return { result: null, status: 500 }
        }
    }

    static register(body) {
        return Api._request("/api/users", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        })
    }

    static async login(body) {
        const { result, status } = await Api._request("/api/login", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        })
        if (status === 200) {
            localStorage.setItem("token", result.token)
        }
        return { result, status }
    }

    static getProfile() {
        return Api._request("/api/users/me", {
            headers: {
                "Content-Type": "application/json", // ??????
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
    }

    static async deleteUser(id) {
        const { result, status } = await Api._request(`/api/users/${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json", // ??????
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
        localStorage.clear()
        return { result, status }
    }

    static updateUser(id, body) {
        return Api._request(`/api/users/${id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(body)
        })
    }

    static upload(body) {
        return Api._request("/api/upload", {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: body
        })
    }

    static getAllPosts() {
        return Api._request(`/api/posts`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
    }

    static getPost(postId) {
        return Api._request(`/api/posts/${postId}`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
    }

    static getPosts(userId) {
        return Api._request(`/api/users/${userId}/posts`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
    }

    static createPost(id, body) {
        return Api._request(`/api/users/${id}/posts`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(body)
        })
    }

    static updatePost(id, body) {
        return Api._request(`/api/posts/${id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(body)
        })
    }

    static deletePost(id) {
        return Api._request(`/api/posts/${id}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
    }

    static getUsers() {
        return Api._request("/api/users", {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
    }

    static getUser(id) {
        return Api._request(`/api/users/${id}`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
    }

    static getFriends(id) {
        return Api._request(`/api/users/${id}/friends`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
    }

    static getFriendRequests() {
        return Api._request("/api/friendship", {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
    }

    static createFriendRequest(body) {
        return Api._request(`/api/friendship`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(body)
        })
    }

    static deleteFriendRequest(body) {
        return Api._request(`/api/friendship`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(body)
        })
    }

    static updateFrindshipStatus(requestId, body) {
        return Api._request(`/api/friendship/${requestId}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(body)
        })
    }

    static isPostLiked(postId) {
        return Api._request(`/api/posts/${postId}/like`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
    }

    static likePost(postId) {
        return Api._request(`/api/posts/${postId}/like`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
    }

    static getComments(postId) {
        return Api._request(`/api/posts/${postId}/comments`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
    }

    static createComment(postId, body) {
        return Api._request(`/api/posts/${postId}/comments`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(body)
        })
    }

    static getMessages(userId) {
        return Api._request(`/api/users/${userId}/messages`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
    }


    static sendMessage(body) {
        return Api._request(`/api/messages`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(body)
        })
    }

    static getChatUsers(userId) {
        return Api._request(`/api/users/${userId}/chats`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
    }

    static updateMessage(messageId, body) {
        return Api._request(`/api/messages/${messageId}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(body)
        })
    }

    static deleteMessages(body) {
        return Api._request(`/api/messages`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(body)
        })
    }

    static readMessage(messageId) {
        return Api._request(`/api/messages/${messageId}/read`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
    }

    static getUnreadMessagesCount(userId) {
        return Api._request(`/api/users/${userId}/messages/unread`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        })
    }

    static sendMailForgotPassword(body) {
        return Api._request("/api/forgot_password", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        })
    }

    static resetPassword(body) {
        return Api._request("/api/reset_password", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        })
    }
}

export default Api