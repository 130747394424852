import { React } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import RegisterForm from "../components/registerForm";

const RegisterPage = () => {
    return (
        <div className="bgColor">
            <Header />
            <RegisterForm/>
            <Footer />
        </div>
    );
}

export default RegisterPage;