import { React } from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Sidebar from "../components/sidebar"
import Feed from "../components/feed"

const MainPage = () => {
    return (
        <div className="bg-secondary">
            <Header />
            <div className="main-content minHeight">
                <Sidebar />
                <Feed />
            </div>
            <Footer />
        </div>
    )
}

export default MainPage