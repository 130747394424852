import React from "react";
const Footer = () => {
    return (
        <footer className="border-bottom bg-dark mt-3 text-center" style={{ bottom: 0}}>
            <p className="text-white p-3">Geekly © Copyright, 2023</p>
            <p className="text-white p-3">Все права защищены (нет)</p>
        </footer>
    );
}

export default Footer;