import { React, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Api from "../modules/Api";
import { useProfile } from "../modules/profileContext";

const HeaderRightSide = () => {
    const { profile, setProfile } = useProfile()
    const navigate = useNavigate()

    useEffect(() => {
        const fetchProfile = async () => {
            const profileResult = await Api.getProfile()
            console.log(profileResult)
            if (profileResult.status === 200) {
                setProfile(profileResult.result.data)
                Api.socket.emit('login', { user_id: profileResult.result.data.id })
            }
            else {
                setProfile(null)
            }
        }
        fetchProfile()
    }, [])

    return (
        <div className="d-flex flex-wrap gap-auto justify-content-end">
            {!profile && (
                <div>
                    <Link className="btn btn-outline-light me-2" to="/login">Войти</Link>
                    <Link className="btn btn-light" to={'/register'}>Регистрация</Link>
                </div>
            ) || (
                    <div className='dropdown'>
                        <img
                            src={`${Api.host}${profile.avatar}`}
                            alt='avatar'
                            width='100px'
                            height='100px'
                            role="button"
                            id="dropdownMenuLink"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        ></img>
                        <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-end text-center" aria-labelledby="dropdownMenuLink">
                            <li><Link to='/profile' className="dropdown-item">Профиль</Link></li>
                            <li><Link to='/settings' className="dropdown-item">Настройки</Link></li>
                            <li><button className="dropdown-item btn btn-danger text-danger" onClick={() => {
                                Api.socket.emit('logout', { user_id: profile.id })
                                setProfile(null)
                                localStorage.clear()
                                navigate('/login')
                            }}>Выйти</button></li>
                        </ul>
                    </div>
                )}
        </div>
    )
}

export default HeaderRightSide