import { React } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Sidebar from "../components/sidebar";
import AllUsersTab from "../components/allUsersTab";

const AllUsersTabPage = () => {
    return (
        <div className="bg-secondary">
            <Header />
            <div className="main-content">
                <Sidebar active={"users"} />
                <AllUsersTab />
            </div>
            <Footer />
        </div>
    )
}

export default AllUsersTabPage