import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ProfileProvider } from './modules/profileContext';
import { UnreadMessagesCountProvider } from './modules/unreadMessagesCountContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ProfileProvider>
        <UnreadMessagesCountProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </UnreadMessagesCountProvider>
    </ProfileProvider>
);