import { useNavigate } from "react-router-dom"
import Api from "../modules/Api"
import { Button, Card, Image } from "react-bootstrap"
import { getFullDate } from "../modules/utils"
import RussianName from "../modules/russianName"
import { useEffect, useState } from "react"

const Comment = ({ comment, comments, setAnswerOnComment, color = "light", onAnswersCountClick = () => { } }) => {
    const navigate = useNavigate()
    const [answerFor, setAnswerFor] = useState(null)
    const [answersCount, setAnswersCount] = useState(0)
    useEffect(() => {
        setAnswerFor(comments.find((value) => value.id === comment.parent_id))
        setAnswersCount(comments.reduce((acc, comm) => {
            if (comm.parent_id === comment.id) {
                acc++
            }
            return acc
        }, 0))
    }, [])
    return (
        <Card className={`bg-${color} ${color == "dark" ? "text-white" : ""} w-100`} style={{ cursor: "pointer" }}>
            <Card.Body className="pb-0 px-0">
                <div className="d-flex align-items-center">
                    <Image
                        src={`${Api.host}${comment.author.avatar}`}
                        roundedCircle
                        className="mx-1 d-flex align-self-start"
                        style={{ cursor: "pointer" }}
                        width={50}
                        height={50}
                        onClick={() => navigate(`/profile/${comment.author.id}`)}
                    />
                    <div>
                        <p className="m-auto fw-bold">{comment.author.name}</p>
                        <p className="text-break m-auto">{comment.text}</p>
                        {answerFor && <small style={{ width: "fit-content" }} className={`d-flex my-auto ${color == "dark" ? "text-white" : "text-muted"}`}>Ответ {new RussianName(answerFor.author.name)?.fullName("dative") || answerFor.author.name}</small>}
                        <small className={`${color == "dark" ? "text-white" : "text-muted"}`}>{getFullDate(comment.date_created)}</small>
                        <Button variant="dark" className={`${color == "dark" ? "text-white" : "text-dark"} bg-transparent mx-2`} style={{ border: "none" }} onClick={(e) => {
                            e.stopPropagation()
                            setAnswerOnComment(comment)
                        }}>Ответить</Button>
                        {answersCount > 0 && <Button variant={color == "dark" ? "light" : "dark"} size="xs" className={`py-0 text-${color}`} style={{ width: "fit-content", height: "fit-content" }} onClick={onAnswersCountClick}>({answersCount} ответов)</Button>}
                    </div>
                </div>
            </Card.Body>
            <div style={{ borderTop: "2px solid grey", width: "100%" }}></div>
        </Card>
    )
}

export default Comment