import { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Api from "../modules/Api";
import RussianName from '../modules/russianName';
import { useUnreadMessagesCount } from '../modules/unreadMessagesCountContext';

const FriendRequestToast = () => {
    useEffect(() => {
        const handleFriendRequest = (data) => {
            console.log(data)
            toast(`${data.name} хочет добавить Вас в друзья`, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
        }

        Api.socket.on("friend_request", handleFriendRequest)

        // Отписываемся от события при размонтировании компонента
        return () => {
            Api.socket.off("friend_request", handleFriendRequest)
        }
    }, [])

    // Ничего не рендерим
    return null
}

const MessageToast = () => {
    const {setUnreadMessagesCount} = useUnreadMessagesCount()
    useEffect(() => {
        const handleReceiveMessage = (data) => {
            setUnreadMessagesCount(prevUnreadMessagesCount => ({
                ...prevUnreadMessagesCount,
                [data.sender.id]: (prevUnreadMessagesCount[data.sender.id] || 0) + 1
            }))
            toast(`Сообщение от ${new RussianName(data.sender.name)?.fullName("genitive") || data.sender.name}: ${data.message.text}`, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
        }

        Api.socket.on("receive_message", handleReceiveMessage)

        // Отписываемся от события при размонтировании компонента
        return () => {
            Api.socket.off("receive_message", handleReceiveMessage)
        }
    }, [])

    // Ничего не рендерим
    return null
}

export { FriendRequestToast, MessageToast }