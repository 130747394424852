import { Container, Spinner } from "react-bootstrap"
import { Link } from "react-router-dom"

const Loader = ({ pleaseEnter }) => (
    <Container className="minHeight d-flex flex-column justify-content-center align-items-center">
        <Spinner animation="border" role="status">
        </Spinner>
        {pleaseEnter && (<>
            <p className="text-white mt-3">Пожалуйста, войдите в аккаунт</p>
            <Link to={"/login"} className="btn btn-dark">Войти</Link>
        </>)}
    </Container>
)

export default Loader