import { useState, useEffect } from "react"
import Api from "../modules/Api"
import UsersCards from "./usersCards"
import { useProfile } from "../modules/profileContext"
import UsersSubTabs from "./usersSubTabs"
import Loader from "./loader"
import { Row } from "react-bootstrap"

const FriendsTab = () => {
    const { profile } = useProfile()
    const [friends, setFriends] = useState([])
    const [friendRequests, setFriendRequests] = useState([])

    const getFriends = async () => {
        const { result, status } = await Api.getFriends(profile?.id)
        if (status === 200) {
            setFriends(result.data)
        }
    }

    const getFriendRequests = async () => {
        const { result, status } = await Api.getFriendRequests()
        if (status === 200) {
            const users = await Promise.all(result.data.map(async request => {
                if (request.sender_id) {
                    const { result, status } = await Api.getUser(request.sender_id)
                    if (status === 200) {
                        return { ...result.data, friendship_id: request.id }
                    }
                }
                return null
            }))
            setFriendRequests(users.filter(user => user !== null))
        }
    }

    useEffect(() => {
        getFriends()
        getFriendRequests()
    }, [profile])

    if (!profile) return <Loader pleaseEnter={true} />
    return (
        <div className="minHeight mt-2">
            <UsersSubTabs active={"friends"}/>
            {friendRequests.length > 0 && (
                <Row className="justify-content-center">
                    <div style={{ borderTop: "2px solid black", marginTop: "15px", width: "18rem" }}></div>
                    <h2 className="text-center">Заявки в друзья</h2>
                    <UsersCards users={friendRequests} requestButtons={true} onUpdate={() => {
                        getFriendRequests()
                        getFriends()
                    }} />
                    <div style={{ borderTop: "2px solid black", marginBottom: "15px", width: "18rem" }}></div>
                </Row>
            )}
            {friends.length > 0 && (
                <UsersCards users={friends} />
            )}
        </div>
    )
}

export default FriendsTab