import React, { useState } from 'react'
import { Card, Row } from 'react-bootstrap'
import Api from '../modules/Api'
import { useUnreadMessagesCount } from '../modules/unreadMessagesCountContext'

const UsersList = ({ users, onUserSelect }) => {
    const [activeUser, setActiveUser] = useState(null)
    const { unreadMessagesCount } = useUnreadMessagesCount()
    const handleUserSelect = (user) => {
        onUserSelect(user)
        setActiveUser(user)
    }
    return (
        <Row className='d-flex flex-row flex-md-column justify-content-center mx-auto'>
            {users.map(user => (
                <Card
                    key={user.id}
                    onClick={() => handleUserSelect(user)}
                    className={`flex-row d-flex justify-content-center align-items-center text-center text-white bg-dark shadow mb-2 rounded mx-1 mx-md-auto ${activeUser?.id === user.id ? 'active-tab' : ''}`}
                    style={{ cursor: "pointer", width: "12rem", transform: "scale(1)", transition: "transform .2s" }}
                    onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                    onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}>
                    <Card.Img src={`${Api.host}${user.avatar}`} alt={user.name} className='my-1' style={{ width: "4rem" }} />
                    <Card.Header className="text-break w-100">{user.name} {unreadMessagesCount[user.id] ? <span style={{ fontWeight: "bold" }}>{`(${unreadMessagesCount[user.id]})`}</span> : ""}</Card.Header>
                </Card>
            ))}
        </Row>
    )
}

export default UsersList