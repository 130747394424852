import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Modal, Button, Row } from 'react-bootstrap'
import Api from '../modules/Api'
import { useProfile } from '../modules/profileContext'
import { useNavigate } from 'react-router-dom'
import { DEFAULT_AVATAR } from '../modules/constants'
import imagePreview from '../modules/imagePreview'

const Settings = () => {
    const [key, setKey] = useState('general')
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showUpdatedModal, setShowUpdatedModal] = useState(false)
    const { profile, setProfile } = useProfile()
    const [response, setResponse] = useState({ result: { errors: [] }, status: "" })
    const { register, handleSubmit, formState, reset } = useForm({ mode: "onChange", defaultValues: profile || {} })
    const [savedProfile, setSavedProfile] = useState(null)
    const [avatarFile, setAvatarFile] = useState(null)
    const [avatarPreview, setAvatarPreview] = useState(null)

    const uploadAvatar = async (file) => {
        const formData = new FormData()
        formData.append('file', file)
        const { result, status } = await Api.upload(formData)
        if (status === 201) {
            const avatarPath = result.data
            setProfile((prevProfile) => ({ ...prevProfile, avatar: avatarPath }))
            setAvatarPreview(`${Api.host}${avatarPath}`)
            return avatarPath
        }
    }

    const onSubmit = async (data, eventKey) => {
        if (eventKey === 'general') {
            if (avatarFile) {
                const newAvatarPath = await uploadAvatar(avatarFile)
                if (newAvatarPath) {
                    updateUserProfile({
                        ...data,
                        avatar: newAvatarPath
                    })
                }
            }
            else {
                updateUserProfile(data)
            }
        }
    }

    const updateUserProfile = async (data) => {
        const { result, status } = await Api.updateUser(profile.id, data)
        setResponse({ result: result, status: status })
        if (status === 204) {
            const profileResult = await Api.getProfile()
            setProfile(profileResult.status === 200 ? profileResult.result.data : null)
            setSavedProfile(profileResult.status === 200 ? profileResult.result.data : null)
        }
        setShowUpdatedModal(true)
    }

    const handleDeleteClick = () => setShowDeleteModal(true)
    const handleDeleteModalClose = () => setShowDeleteModal(false)
    const handleDelete = async () => {
        await Api.deleteUser(profile.id)
        setProfile(null)
        handleDeleteModalClose()
        navigate('/login')
    }
    const handleCancelChanges = () => {
        setAvatarPreview(savedProfile?.avatar ? `${Api.host}${savedProfile.avatar}` : DEFAULT_AVATAR)
        setAvatarFile(null)
        reset(savedProfile)
    }
    useEffect(() => {
        reset(profile)
        setSavedProfile(profile)
        if (profile) {
            setAvatarPreview(profile.avatar ? `${Api.host}${profile.avatar}` : DEFAULT_AVATAR)
        }
    }, [profile, reset])

    const handleDeleteModalCloseUpdatedModal = () => setShowUpdatedModal(false)

    const handleRemoveAvatar = () => {
        setAvatarFile(null)
        setAvatarPreview(`${Api.host}${DEFAULT_AVATAR}`)
    }
    const navigate = useNavigate()
    return (
        <div className='minHeight'>
            <div className="main-tabs d-flex align-content-start m-auto mt-2 align-items-center">
                <button className="btn btn-dark" onClick={() => setKey('general')}>Основные</button>
            </div>
            <div className="content mt-2 justify-content-center align-items-center mx-0 mx-md-3">
                {key === 'general' &&
                    (<Row className='bg-light w-100 m-auto'>
                        <Form.Group className="mb-3">
                            <div className="avatar-container">
                                <div className="avatar-upload mt-3">
                                    <Form.Control
                                        type="file"
                                        id="avatar"
                                        name="avatar"
                                        onChange={(e) => imagePreview(e.target.files[0], setAvatarFile, setAvatarPreview)}
                                        className="d-none"
                                        accept="image/*"
                                    />
                                    <label htmlFor="avatar" className="avatar-preview" style={{ backgroundImage: `url(${avatarPreview})` }}>
                                        <div className="avatar-edit-overlay"></div>
                                    </label>
                                </div>
                                {avatarPreview !== `${Api.host}${DEFAULT_AVATAR}` && (
                                    <button className="btn btn-outline-dark d-block mx-auto mt-2" onClick={handleRemoveAvatar}>Удалить аватар</button>
                                )}
                            </div>
                        </Form.Group>
                        <Form onSubmit={handleSubmit((data) => onSubmit(data, 'general'))} noValidate className='col-12 col-md-6 m-auto text-center'>
                            <Form.Group className='mb-3'>
                                <Form.Label>Электронная почта</Form.Label>
                                <Form.Control
                                    type="email"
                                    className={`${formState.errors.email ? "is-invalid" : "is-valid"}`}
                                    id="email"
                                    name="email"
                                    {...register("email", { required: true, pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ })}
                                />
                                {formState.errors.email && formState.errors.email.type === "required" && (<div className="invalid-feedback">Пожалуйста, введите действительный адрес электронной почты</div>)}
                                {formState.errors.email && formState.errors.email.type === "pattern" && (<div className="invalid-feedback">Пожалуйста, введите корректный адрес электронной почты</div>)}
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>Имя</Form.Label>
                                <Form.Control
                                    type="text"
                                    className={`${formState.errors.name ? "is-invalid" : "is-valid"}`}
                                    id="name"
                                    name="name"
                                    {...register("name", { required: true, minLength: 3 })}
                                />
                                {formState.errors.name && formState.errors.name.type === "required" && (<div className="invalid-feedback">Пожалуйста, введите имя</div>)}
                                {formState.errors.name && formState.errors.name.type === "minLength" && (<div className="invalid-feedback">Пожалуйста, введите имя длиной не менее 3 символов</div>)}
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>Имя пользователя</Form.Label>
                                <Form.Control
                                    type="text"
                                    className={`${formState.errors.username ? "is-invalid" : "is-valid"}`}
                                    id="username"
                                    name="username"
                                    {...register("username", { required: true, minLength: 5 })}
                                />
                                {formState.errors.username && formState.errors.username.type === "required" && (<div className="invalid-feedback">Пожалуйста, введите имя пользователя</div>)}
                                {formState.errors.username && formState.errors.username.type === "minLength" && (<div className="invalid-feedback">Пожалуйста, введите имя пользователя длиной не менее 5 символов</div>)}
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>Кто может отправлять мне сообщения</Form.Label>
                                <Form.Control
                                    as="select"
                                    id="message_privacy"
                                    name="message_privacy"
                                    {...register("message_privacy", { required: true })}
                                >
                                    <option value="all">Все</option>
                                    <option value="friends">Только друзья</option>
                                </Form.Control>
                            </Form.Group>
                            <div className="d-grid">
                                <button type="submit" className="btn btn-dark mt-3">Сохранить изменения</button>
                                <p className="btn btn-dark mt-3" onClick={handleCancelChanges}>Отменить изменения</p>
                            </div>
                        </Form>
                        <div className='mt-3 mb-3 text-center'>
                            <p className='d-inline p-2'>Удалить аккаунт</p>
                            <button onClick={handleDeleteClick} className='btn btn-dark text-danger d-inline'>Удалить</button>
                        </div>
                    </Row>
                    )
                }
            </div>

            <Modal show={showDeleteModal} onHide={handleDeleteModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Удаление аккаунта</Modal.Title>
                </Modal.Header>
                <Modal.Body>Вы уверены, что хотите удалить ваш аккаунт? <span className='text-danger'>Это действие необратимо.</span></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteModalClose}>Закрыть</Button>
                    <Button variant="danger" onClick={handleDelete}>Удалить аккаунт</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showUpdatedModal} onHide={handleDeleteModalCloseUpdatedModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{response.status == 204 && "Профиль обновлен" || "Ошибка обновления профиля"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{response.status == 204 && "Данные профиля успешно обновлены" || `Что-то пошло не так: ${response.result.errors.join('\n')}`}</Modal.Body>
                <Modal.Footer>
                    <Button variant={response.status == 204 && "success" || "secondary"} onClick={handleDeleteModalCloseUpdatedModal}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Settings