import { useEffect, useState, useRef } from 'react'
import { Form, Button, Row, Col, Container, Image, Modal } from 'react-bootstrap'
import Api from '../modules/Api'
import Message from './message'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import RussianName from '../modules/russianName'
import { canSendMessage, formatMessageDate, isMessageEditable, isSameDay } from '../modules/utils'
import Picker from 'emoji-picker-react'
import { useUnreadMessagesCount } from '../modules/unreadMessagesCountContext'
import { useProfile } from '../modules/profileContext'

const ChatWindow = ({ selectedUser, chatUsers, setChatUsers, userSearchTerm }) => {
    const navigate = useNavigate()
    const { register, handleSubmit, reset, setValue, getValues } = useForm()
    const [messages, setMessages] = useState([])
    const [showEmojiPicker, setShowEmojiPicker] = useState(false)
    const [selectedMessages, setSelectedMessages] = useState([])
    const [selectionMode, setSelectionMode] = useState(false)
    const [editMsgMode, setEditMsgMode] = useState(false)
    const [readMessages, setReadMessages] = useState([])
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const { setUnreadMessagesCount } = useUnreadMessagesCount()
    const { profile } = useProfile()
    const messagesEndRef = useRef(null)
    // состояние для хранения времени начала нажатия
    const pressTimer = useRef(null)

    // Функция для обработки начала удерживания
    const handleTouchStart = (message) => {
        pressTimer.current = setTimeout(() => handleLongPress(message), 1000)
    }

    // Функция для обработки окончания удерживания
    const handleTouchEnd = () => {
        clearTimeout(pressTimer.current)
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const handleDeleteModalClose = () => {
        setShowDeleteModal(false)
    }

    const handleDeleteModalConfirm = () => {
        deleteSelectedMessages()
        setShowDeleteModal(false)
    }

    const readMessage = async (messageId) => {
        const { status } = await Api.readMessage(messageId)
        if (status === 204) {
            setReadMessages(prevReadMessages => [...prevReadMessages, messageId])
            setUnreadMessagesCount(prevUnreadMessagesCount => {
                const updatedUnreadMessagesCount = { ...prevUnreadMessagesCount }
                if (updatedUnreadMessagesCount[selectedUser?.id]) {
                    updatedUnreadMessagesCount[selectedUser?.id] = updatedUnreadMessagesCount[selectedUser?.id] - 1;
                }
                return updatedUnreadMessagesCount
            });
            Api.socket.emit("read_message", messageId)
        }
    }

    const getChatUsers = async () => {
        const { result, status } = await Api.getChatUsers(profile?.id)
        if (status === 200) {
            setChatUsers(result.data)
            if (userSearchTerm) {
                setChatUsers(prevUsers => prevUsers.filter(user => user.name.toLowerCase().indexOf(userSearchTerm.toLowerCase()) != -1))
            }
        }
    }

    useEffect(() => {
        exitSelectionMode()
        exitEditMsgMode()

        fetchChatHistory()
        const handleReceiveMessage = (data) => {
            if (data.sender.id === selectedUser?.id) {
                setMessages(prevMessages => [...prevMessages, data.message])
            }
        }

        const handleUpdateMessage = (data) => {
            if (data.sender_id === selectedUser?.id) {
                setMessages(prevMessages => prevMessages.map(msg => {
                    if (msg.id === data.id) {
                        return data
                    }
                    return msg
                }))
            }
        }

        const handleDeleteMessages = (data) => {
            setMessages(prevMessages => prevMessages.filter(message => !data.includes(message.id)))
        }

        const handleReadMessage = (messageId) => {
            setMessages(prevMessages => prevMessages.map(msg => {
                if (msg.id == messageId) {
                    return { ...msg, is_read: true }
                }
                return msg
            }))
        }

        Api.socket.on("receive_message", handleReceiveMessage)
        Api.socket.on("get_update_message", handleUpdateMessage)
        Api.socket.on("get_delete_message", handleDeleteMessages)
        Api.socket.on("readed_message", handleReadMessage)
        // Отписываемся от события при размонтировании компонента
        return () => {
            Api.socket.off("receive_message", handleReceiveMessage)
            Api.socket.off("get_update_message", handleUpdateMessage)
            Api.socket.off("get_delete_message", handleDeleteMessages)
            Api.socket.off("readed_message", handleReadMessage)
        }
    }, [selectedUser?.id])

    useEffect(() => {
        getChatUsers()
        scrollToBottom()
        messages.forEach(message => {
            if (!readMessages.includes(message.id) && message.sender_id == selectedUser.id) {
                readMessage(message.id)
            }
        })
    }, [messages])

    const fetchChatHistory = async () => {
        const { status, result } = await Api.getMessages(selectedUser?.id)
        if (status == 200) {
            setMessages(result.data)
        }
    }

    const onSubmit = async (data) => {
        console.log(data)
        const { status, result } = await Api.sendMessage({ receiver_id: selectedUser?.id, text: data.text })
        if (status == 201) {
            setMessages(prevMessages => [...prevMessages, result.data])
            reset()
        }
    }

    const handleLongPress = (message) => {
        enterSelectionMode()
        if (selectedMessages.includes(message)) {
            setSelectedMessages(selectedMessages.filter((msg) => msg !== message))
        }
        else {
            setSelectedMessages([...selectedMessages, message])
        }

        if (selectedMessages.length == 0) {
            exitEditMsgMode()
        }
    }

    const canEditMessage = () => {
        return selectedMessages.length === 1 && selectedMessages[0].sender_id == profile.id && isMessageEditable(selectedMessages[0].date_created)
    }

    const canDeleteMessages = () => {
        return selectedMessages.length > 0 && selectedMessages.some(message => message.sender_id == profile.id)
    }

    const deleteSelectedMessages = async () => {
        if (!canDeleteMessages()) return
        const messageIds = selectedMessages.map(message => message.id)

        if (messageIds.length == 0) return

        const { status, result } = await Api.deleteMessages({ receiver_id: selectedUser.id, message_ids: messageIds })
        if (status == 200) {
            Api.socket.emit('get_delete_message', result.data)
            setMessages(prevMessages => prevMessages.filter(message => !result.data.includes(message.id)))
            exitSelectionMode()
        }
    }

    const enterSelectionMode = () => {
        setSelectionMode(true)
    }

    const exitSelectionMode = () => {
        setSelectionMode(false)
        setSelectedMessages([])
    }

    const exitEditMsgMode = () => {
        setEditMsgMode(false)
        reset()
    }

    const onEmojiClick = (emojiObject) => {
        setValue("text", getValues('text') + emojiObject.emoji)
    }

    const handleEditMessage = async () => {
        if (!canEditMessage()) return
        const message = selectedMessages[0]
        const newText = getValues('text')
        const { status } = await Api.updateMessage(message.id, { text: newText })
        if (status == 204) {
            setMessages(prevMessages => prevMessages.map(msg => {
                if (msg.id === message.id) {
                    return { ...msg, text: newText, is_edited: true }
                }
                return msg
            }))
            Api.socket.emit("update_message", { id: message.id, text: newText, receiver_id: selectedUser.id })
        }
        exitSelectionMode()
        exitEditMsgMode()
    }

    return (
        <Container className="d-flex flex-column justify-content-between align-items-center bg-dark w-100 p-0" style={{ height: '100%' }}>
            {!selectedUser && <h2 className="m-auto text-white">Выберите чат</h2> || !canSendMessage(selectedUser, chatUsers) && (
                <>
                    <h2 className='text-white'>Добавьте {(new RussianName(selectedUser.name)?.fullName("genitive") || selectedUser.name)} в друзья, чтобы общаться</h2>
                    <Button variant='light' onClick={() => navigate(`/profile/${selectedUser.id}`)}>Перейти</Button>
                </>
            ) || (
                    <>
                        <Row className="align-items-center" style={{ cursor: "pointer" }} onClick={() => navigate(`/profile/${selectedUser.id}`)}>
                            <Col xs="auto">
                                <Image className='my-1' src={`${Api.host}${selectedUser.avatar}`} roundedCircle style={{ height: "5rem", width: "5rem" }}></Image>
                            </Col>
                            <Col>
                                <h2 className='text-white'>{selectedUser.name}</h2>
                            </Col>
                        </Row>
                        {selectionMode && (
                            <div className='d-flex justify-content-between gap-1'>
                                <Button variant="outline-danger" onClick={() => setShowDeleteModal(true)} disabled={!canDeleteMessages()}>Удалить</Button>
                                {canEditMessage() && <Button variant="outline-light" onClick={() => {
                                    setEditMsgMode(true)
                                    setValue("text", selectedMessages[0].text)
                                }}>Редактировать</Button>}
                                <Button variant="outline-light" onClick={exitSelectionMode}>Отмена</Button>
                            </div>
                        )}
                        <div className="chat-messages">
                            {messages.map((message, index) => (
                                <div key={index}
                                    onTouchStart={() => handleTouchStart(message)}
                                    onTouchEnd={handleTouchEnd}
                                    onMouseDown={() => handleTouchStart(message)}
                                    onMouseUp={handleTouchEnd}
                                    onMouseLeave={handleTouchEnd}>
                                    {(index === 0 || !isSameDay(new Date(message.date_created), new Date(messages[index - 1].date_created))) && (
                                        <div className="date-separator" key={`${index}_date`}>
                                            {formatMessageDate(new Date(message.date_created))}
                                        </div>
                                    )}
                                    <Message key={index} message={message} isOwnMessage={message.sender_id == profile.id} isSelected={selectedMessages.includes(message)} />
                                </div>
                            ))}
                            <div ref={messagesEndRef} />
                        </div>
                        <Form onSubmit={handleSubmit(onSubmit)} className='w-100 px-2'>
                            <Row className="d-flex align-items-center">
                                <Col xs={12} md className="p-0 p-md-3">
                                    <Form.Group>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            {...register("text", {
                                                required: true,
                                                validate: value => value.trim().length !== 0
                                            })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md="auto" className="d-flex justify-content-center my-2 my-md-0">
                                    <Button variant='outline-light' className='me-2' onClick={() => setShowEmojiPicker(!showEmojiPicker)}>Эмодзи</Button>
                                    {!editMsgMode && <Button variant="light" type="submit">Отправить</Button> || (
                                        <>
                                            <Button variant="light" onClick={handleEditMessage}>Сохранить</Button>
                                            <Button variant='outline-light' className='mx-2' onClick={exitEditMsgMode}>Отмена</Button>
                                        </>
                                    )}
                                </Col>
                                {showEmojiPicker && <Picker width="100%" className='mb-2' onEmojiClick={onEmojiClick} />}
                            </Row>
                        </Form>
                    </>
                )}
            <Modal show={showDeleteModal} onHide={handleDeleteModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Подтверждение удаления</Modal.Title>
                </Modal.Header>
                <Modal.Body>Вы уверены, что хотите удалить выбранные сообщения?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteModalClose}>Отмена</Button>
                    <Button variant="danger" onClick={handleDeleteModalConfirm}>Удалить</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default ChatWindow