import { Link } from "react-router-dom"

const UsersSubTabs = ({active}) => {
    return (
        <div className="sub-tabs mb-2 gap-1 m-auto" style={{ width: "fit-content" }}>
            <Link to={'/users'} className={`btn btn-dark ${active == "users" && "active-tab" || ''}`} style={{ minWidth: "120px" }}>Все</Link>
            <Link to={'/friends'} className={`btn btn-dark ${active == "friends" && "active-tab" || ''}`} style={{ minWidth: "120px" }}>Мои друзья</Link>
        </div>
    )
}
export default UsersSubTabs