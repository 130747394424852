import { useState } from "react"
import Comment from "./comment"

const Comments = ({ comments, setAnswerOnComment }) => {
    const [visible, setVisible] = useState({})
    const renderComments = (comments, parentId, color) => {
        return comments.map((comment) => {
            if (comment.parent_id === parentId) {
                return (<div>
                    <Comment
                        key={comment.id}
                        color={color}
                        comment={comment}
                        setAnswerOnComment={setAnswerOnComment}
                        comments={comments}
                        onAnswersCountClick={() => {
                            setVisible(prev => ({ ...prev, [comment.id]: !prev[comment.id] }))
                        }}
                    >
                    </Comment>
                    <div className={`${(visible[comment.id]) ? "d-block" : "d-none"}`}>
                        {renderComments(comments, comment.id, "dark")}
                    </div>
                </div>
                )
            }
            return null
        })
    }

    return (
        <div className="bg-light w-100 comments">
            {renderComments(comments, null)}
        </div>
    )
}

export default Comments