import { React } from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import ResetPasswordForm from "../components/resetPasswordForm"
import { useSearchParams } from "react-router-dom"
import SetPasswordForm from "../components/setPasswordForm"

const ResetPasswordFormPage = () => {
    const [queryParams] = useSearchParams()
    return (
        <div className="bgColor">
            <Header />
            {!queryParams.get("token") && <ResetPasswordForm /> || <SetPasswordForm />}
            <Footer />
        </div>
    );
}

export default ResetPasswordFormPage