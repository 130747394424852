import { React } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Sidebar from "../components/sidebar";
import FriendsTab from "../components/friendsTab";

const FriendsTabPage = () => {
    return (
        <div className="bg-secondary">
            <Header />
            <div className="main-content">
                <Sidebar active={"users"} />
                <FriendsTab />
            </div>
            <Footer />
        </div>
    )
}

export default FriendsTabPage