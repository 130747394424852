import React, { createContext, useState, useContext } from 'react';

const UnreadMessagesCountContext = createContext({})

export const useUnreadMessagesCount = () => useContext(UnreadMessagesCountContext)

export const UnreadMessagesCountProvider = ({ children }) => {
    const [unreadMessagesCount, setUnreadMessagesCount] = useState({})

    return (
        <UnreadMessagesCountContext.Provider value={{ unreadMessagesCount, setUnreadMessagesCount }}>
            {children}
        </UnreadMessagesCountContext.Provider>
    )
}