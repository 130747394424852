import React from "react";
import { Link } from "react-router-dom";
import logo from '../images/logo.png'
import HeaderRightSide from "./headerRightSide";

const Header = () => {
    return (
        <nav className="navbar navbar-dark bg-dark">
            <div className="container-fluid d-flex align-items-center justify-content-between">
                <div className="navbar-brand d-flex flex-shrink-0">
                    <Link to="/">
                        <img src={logo} alt="logo" className="img-fluid" width="100px"/>
                    </Link>
                </div>
                <h1 className="text-white m-auto flex-grow-1 text-center p-3 d-none d-md-block">Geekly</h1>
                <HeaderRightSide />
            </div>
        </nav>
    )
}

export default Header