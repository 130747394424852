import { React } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Sidebar from "../components/sidebar";
import MessagesTab from "../components/messagesTab";

const MessagesTabPage = () => {
    return (
        <div className="bg-secondary">
            <Header />
            <div className="main-content">
                <Sidebar active={"messages"} />
                <MessagesTab />
            </div>
            <Footer />
        </div>
    )
}

export default MessagesTabPage