const getFullDate = (date) => new Date(date).toLocaleDateString('ru-RU', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
})

const canSendMessage = (profile, chats) => {
    const isChat = chats.some(user => user.id === profile.id)
    return profile.message_privacy === "all" || isChat || profile.friendship_status == "accepted"
}

const formatMessageDate = (date) => {
    const today = new Date()
    const yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)

    if (date.toDateString() === today.toDateString()) {
        return 'Сегодня'
    }
    else if (date.toDateString() === yesterday.toDateString()) {
        return 'Вчера'
    }
    else {
        return date.toLocaleDateString('ru-RU', { day: 'numeric', month: 'long' })
    }
}

const isSameDay = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
}

const isPostEditable = (date_created) => Math.abs(new Date() - new Date(date_created)) / 36e5 <= 8
const isMessageEditable = isPostEditable // Типа одно и то же, но может же потом быть и не одно и то же, поэтому на всякий случай две разные функции. 8 часов от создания сообщения - можно редактировать

const maskEmail = (string) => string.replace(
        /(..)(.{1,2})(?=.*@)/g,
        (_, a, b) => a + '*'.repeat(b.length)
    )

export {getFullDate, canSendMessage, formatMessageDate, isSameDay, isPostEditable, isMessageEditable, maskEmail}