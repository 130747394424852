import { useState, useEffect } from "react"
import Api from "../modules/Api"
import UsersCards from "./usersCards"
import { useProfile } from "../modules/profileContext"
import UsersSubTabs from "./usersSubTabs"
import Loader from "./loader"

const AllUsersTab = () => {
    const { profile } = useProfile()
    const [users, setUsers] = useState([])

    const getUsers = async () => {
        const { result, status } = await Api.getUsers()
        console.log(result, status)
        if (status === 200) {
            setUsers(result.data)
        }
    }

    useEffect(() => {
        getUsers()
    }, [])

    if (!profile) return <Loader pleaseEnter={true} />
    return (
        <div className="minHeight mt-2">
            <UsersSubTabs active={"users"}/>
            <UsersCards users={users.filter(user => user.id !== profile.id)} />
        </div>
    )
}

export default AllUsersTab