import { React, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { Button, Modal, Form, Container, Row, Col } from "react-bootstrap"
import Api from "../modules/Api"
import { useProfile } from "../modules/profileContext"

const LoginForm = () => {
    const { setProfile } = useProfile()
    const navigate = useNavigate()
    const [response, setResponse] = useState({ result: { errors: [] }, status: "" })
    const { register, handleSubmit, formState } = useForm({ mode: "onChange" })

    const onSubmit = async (data) => {
        const { result, status } = await Api.login(data)
        setResponse({ result, status })
        if (status === 200) {
            const profileResult = await Api.getProfile()
            console.log(profileResult)
            setProfile(profileResult.status === 200 ? profileResult.result.data : null)
            Api.socket.emit('login', { user_id: profileResult.result.data.id })
            navigate("/")
        }
    }

    const closeModal = () => {
        setResponse({ result: { errors: [] }, status: "" })
    }

    return (
        <Container className="minHeight">
            <Row className="justify-content-center">
                <Col md={6} className="mt-3 pb-3 bg-light rounded shadow">
                    <h1 className="text-center">Вход</h1>
                    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="login">Электронная почта или имя пользователя</Form.Label>
                            <Form.Control
                                type="text"
                                id="login"
                                name="login"
                                {...register("login", { required: true })}
                                isValid={formState.touchedFields.login && !formState.errors.login}
                                isInvalid={!!formState.errors.login}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formState.errors.login?.type === "required" && "Пожалуйста, введите электронную почту или имя пользователя"}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Пароль</Form.Label>
                            <Form.Control
                                type="password"
                                id="password"
                                name="password"
                                isValid={formState.touchedFields.password && !formState.errors.password}
                                isInvalid={!!formState.errors.password}
                                {...register("password", { required: true })}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formState.errors.password?.type === "required" && "Пожалуйста, введите пароль"}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <div className="d-grid">
                            <button type="submit" className="btn btn-dark">Войти</button>
                            <Link to="/reset-password" className="btn btn-dark mt-2">Восстановить пароль</Link>
                        </div>
                    </Form>
                </Col>
            </Row>
            {
                response.status !== 200 &&
                <Modal show={response.status} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Упс...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{`Что-то пошло не так: ${response.result.errors.join('\n')}`}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>Закрыть</Button>
                    </Modal.Footer>
                </Modal>
            }
        </Container>
    )
}

export default LoginForm