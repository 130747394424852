import { Link } from "react-router-dom"
import { useUnreadMessagesCount } from "../modules/unreadMessagesCountContext"
import { useEffect, useMemo } from "react"
import { useProfile } from "../modules/profileContext"
import Api from "../modules/Api"

const Sidebar = ({ active }) => {
    const { profile } = useProfile()
    const { setUnreadMessagesCount, unreadMessagesCount } = useUnreadMessagesCount()

    useEffect(() => {
        const getUnreadMessagesCount = async () => {
            const { result, status } = await Api.getUnreadMessagesCount(profile?.id)
            if (status === 200) {
                setUnreadMessagesCount(result.data)
            }
        }
        getUnreadMessagesCount()
    }, [profile])

    const totalUnreadMessages = useMemo(() => Object.values(unreadMessagesCount).filter(count => count > 0).length, [unreadMessagesCount])
    return (
        <div className="tabs sidebar px-1">
            <div className="main-tabs mt-2 gap-1 mx-0 mx-md-1">
                <Link to={"/users"} className={`btn btn-dark w-100 ${active === "users" && "active-tab" || ""}`}>Друзья</Link>
                <Link to={'/messages'} className={`btn btn-dark w-100 ${active === "messages" && "active-tab" || ""}`}>
                    Сообщения {totalUnreadMessages > 0 && `(${totalUnreadMessages})`}
                </Link>
            </div>
        </div>
    )
}
export default Sidebar