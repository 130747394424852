import { Routes, Route } from 'react-router-dom';
import './css/style.css';
import MainPage from './pages/mainPage';
import RegisterPage from './pages/registerPage';
import LoginPage from './pages/loginPage';
import SettingsPage from './pages/settingsPage'
import ProfilePage from './pages/profilePage';
import { ToastContainer } from 'react-toastify';
import { FriendRequestToast, MessageToast } from './components/socketProcessing';
import AllUsersTabPage from './pages/allUsersTabPage';
import FriendsTabPage from './pages/friendsTabPage';
import MessagesTabPage from './pages/messagesTabPage';
import ResetPasswordFormPage from './pages/resetPasswordFormPage';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path={'/'} element={<MainPage />} />
        <Route path={'/register'} element={<RegisterPage />} />
        <Route path={'/login'} element={<LoginPage />} />
        <Route path={'/settings'} element={<SettingsPage />} />
        <Route path={'/profile/:id'} element={<ProfilePage />} />
        <Route path={'/profile'} element={<ProfilePage />} />
        <Route path={'/users'} element={<AllUsersTabPage />} />
        <Route path={'/friends'} element={<FriendsTabPage />} />
        <Route path={'/messages'} element={<MessagesTabPage />} />
        <Route path={'/messages/:id'} element={<MessagesTabPage />} />
        <Route path={'/reset-password'} element={<ResetPasswordFormPage />} />
      </Routes>
      <ToastContainer />
      <FriendRequestToast />
      <MessageToast/>
    </div>
  );
}

export default App;